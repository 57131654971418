
import Homepage from "./component/Homepage";


function App() {
  return (
   <>
   <div >
 <Homepage/>
   </div>
   </>
  );
}

export default App;
